<template>
  <div class="root">
    <h1>Dashboard</h1>
  </div>
</template>

<script>
export default {
  name: 'Dashboard'
}
</script>

<style scoped lang="scss">

</style>
